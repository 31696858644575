import React from 'react'
import { Button, Modal } from 'react-bootstrap'


type Props = {
  children?: React.ReactNode
  onClose: () => void
  title: string
  size?: 'sm' | 'lg' | 'xl' |  undefined
}
export const CustomModal: React.FC<Props> = ({ children, onClose, title = "SET TITLE",size=undefined}) => {
  return (
    <Modal centered show={true} size={size} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className='p-2'>
        <Button size='sm' variant="danger" onClick={onClose}>
          Cerrar
        </Button>
        <Button type='submit' form='new-form' size='sm' variant="success" >
          Guardar Cambios
        </Button>
      </Modal.Footer>
    </Modal>
  )
}